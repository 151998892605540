import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { Sort } from '@angular/material/sort';
import { FormGroup } from '@angular/forms';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class PGIService {

  hideTopMenu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private requestService: RequestService) { }

  async getPGIOverview(filters: any = {}): Promise<void> {
    // console.log(postData);
    return new Promise((resolve, reject) => {
      let postData: any = {};
      let agencyID = this.requestService.currentUser?.mirror_1__1;

      // if (postData.agency) {
      //   postData.agency_name = postData.agency;
      // }

      postData.agency = agencyID;
      postData.agency_name = filters.agency_name;
      postData.agent = filters.agent;
      postData.carrier = filters.carrier;
      postData.state = filters.state;
      postData.productline = filters.productline;
      postData.lob = filters.lob;

      if (filters.dateType) {
        if (filters.dateType == 'CUSTOM') {
          postData.date = `${moment(filters.startDate).format('yyyyMM')}-${moment(filters.endDate).format('yyyyMM')}`;
        }
        else {
          postData.date = filters.dateType;
        }
      }
      // postData = {
      //   agency: agencyID, // 'y7q56QsPOb98kC_yCtrmXN5DR4_6-z_wH4xqykpxaYY1',
      //   // agency: 'WplIQFgC0iUOXClzDYn6V5BLDABzqHesm7Q7RUZUGpg1',
      //   // lob: 'Homeowners',
      //   // state: 'Colorado',
      //   // productline: 'Personal',
      //   // agent: 'Osmaylin Henriquez',
      //   // carrier: 'Allied',
      //   // test: false,
      // };

      this.requestService.postRequest('pgi', '', postData, (data, error) => {
        if (data) {
          resolve(data);
        }
        else {
          reject(error);
        }
      });
    });
  }

  async getPGIOverviewFilter(agencyName: string = '', lob: any = [], state: any = [], productline: any = [], agent: any = [], carrier: any = []): Promise<void> {
    return new Promise((resolve, reject) => {
      let agencyID = this.requestService.currentUser?.mirror_1__1;
      let isAgent = this.requestService.isUserAgent();
      if (isAgent) {
        agencyName = this.requestService.currentUser?.link_to_agencies5;
      }

      let postData: any = {
        agency: agencyID,
        // lob: 'Homeowners',
        // state: 'Colorado',
        // productline: 'Personal',
        // agent: 'Osmaylin Henriquez',
        // carrier: 'Allied',
        // test: false,
      };

      if (agencyName) {
        postData.agency_name = agencyName;
      }

      if (lob?.length) {
        postData.lob = lob;
      }

      if (state?.length) {
        postData.state = state;
      }

      if (productline?.length) {
        postData.productline = productline;
      }

      if (agent?.length) {
        postData.agent = agent;
      }

      if (carrier?.length) {
        postData.carrier = carrier;
      }

      this.requestService.postRequest('pgifilter', '', postData, (data, error) => {
        if (data) {
          resolve(data);
        }
        else {
          reject(error);
        }
      });
    });
  }


  async getProfitabilityRetentionOverview(): Promise<any> {
    return new Promise((resolve, reject) => {
      let agencyID = this.requestService.currentUser?.mirror_1__1;
      let postData = {
        agency: agencyID,
      };

      this.requestService.postRequest('profitabilityall', '', postData, (data, error) => {
        if (data) {

          resolve(data);
        }
        else {
          reject(error);
        }
      });
    });
  }
  async getPGITargetedOpportunities(): Promise<any> {
    return new Promise((resolve, reject) => {
      let agencyID = this.requestService.currentUser?.mirror_1__1;
      let postData = {
        agency: agencyID,
      };

      this.requestService.postRequest('opportunitiesAll', '', postData, (data, error) => {
        if (data) {
          resolve(data);
        }
        else {
          reject(error);
        }
      });
    });
  }

  async getOpportunitiesDetails(
    pageIndex: number,
    pageSize: number,
    telematics: string,
    sort: Sort
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let agencyID = this.requestService.currentUser?.mirror_1__1;
      let postData: any = {
        agency: agencyID,
        page_num: pageIndex,
        page_size: pageSize,
        type: 'telematics',
        telematics: telematics,
      };

      if (sort) {
        postData.sort_by = sort.active;
        postData.sort_direction = sort.direction.toUpperCase() || 'ASC';
      }

      this.requestService.postRequest('opportunitiesDetails', '', postData, (data, error) => {
        if (data) {
          resolve(data);
        }
        else {
          reject(error);
        }
      });
    });
  }

  // getFilter() {
  //   return this.http.get<any[]>('assets/data/dashboard.json');
  // }

  // getAgencyList() {
  //   return this.http.get<any[]>('assets/data/agencies.json');
  // }
  // getAgentList() {
  //   return this.http.get<any[]>('assets/data/agents.json');
  // }
  // getlobsList() {
  //   return this.http.get<any[]>('assets/data/lobs.json');
  // }
  // getStateList() {
  //   return this.http.get<any[]>('assets/data/states.json');
  // }
  // getProductList() {
  //   return this.http.get<any[]>('assets/data/products.json');
  // }
  // getCarrierList() {
  //   return this.http.get<any[]>('assets/data/carriers.json');
  // }
  getVideoList() {
    return this.http.get<any[]>('assets/data/videos.json');
  }
  // getNotifications() {
  //   return this.http.get<any[]>('assets/data/previousNotifications.json');
  // }
  // getPgi() {
  //   return this.http.get<any[]>('assets/data/pgi.json');
  // }
  // getOpportunities() {
  //   return this.http.get<any[]>('assets/data/opportunitiesDetails.json');
  // }

  async getPGIPoliciesOverviewDetails(pageSize: any = 10, pageNum: any = 0, sort: Sort, form: FormGroup): Promise<any> {
    return new Promise((resolve, reject) => {
      let agencyID = this.requestService.currentUser?.mirror_1__1;

      let postData: any = {
        agency: agencyID,
        type: 'policies',
        page_num: pageNum,
        page_size: pageSize,
      };

      if (form.controls.city.getRawValue() != 'All Cities') {
        postData.city = form.controls.city.getRawValue();
      }

      if (form.controls.lob.getRawValue() != 'All LOBs') {
        postData.lob = form.controls.lob.getRawValue();
      }

      if (form.controls.term.getRawValue() != 'All Terms') {
        postData.term = form.controls.term.getRawValue();
      }

      if (form.controls.carrier.getRawValue() != 'All Carriers') {
        postData.carriername = form.controls.carrier.getRawValue();
      }

      if (form.controls.agency.getRawValue() != 'All Agencies') {
        postData.agency_name = form.controls.agency.getRawValue();
      }

      if (form.controls.type.getRawValue() != 'All Product Lines') {
        postData.productline = form.controls.type.getRawValue();
      }

      if (form.controls.ratingState.getRawValue() != 'All States') {
        postData.state = form.controls.ratingState.getRawValue();
      }

      if (form.controls.transactionType.getRawValue() != 'All Transaction Types') {
        postData.transactiontype = form.controls.transactionType.getRawValue();
      }

      if (sort) {
        postData.sort_by = sort.active;
        postData.sort_direction = sort.direction.toUpperCase();
      }

      this.requestService.postRequest('overviewdetails', '', postData, (data, error) => {
        if (data) {
          resolve(data);
        }
        else {
          reject(error);
        }
      });
    });
  }

  public getProductionData(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.requestService.postRequest('productionall', '', {
        agency: this.requestService.currentUser?.mirror_1__1
      }, (data, error) => {
        if (data) {
          resolve(data);
        }
        else {
          reject(error);
        }
      })
    });
  }

  async getProductionDetails(
    pageIndex: number,
    pageSize: number,
    postData: any,
    sort: Sort
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let agencyID = this.requestService.currentUser?.mirror_1__1;
      let data: any = {
        agency: agencyID,
        page_num: pageIndex,
        page_size: pageSize,
        ...postData
      };

      if (sort) {
        data.sort_by = sort.active;
        data.sort_direction = sort.direction.toUpperCase() || 'ASC';
      }

      this.requestService.postRequest('productionDetails', '', data, (data, error) => {
        if (data) {
          resolve(data);
        }
        else {
          reject(error);
        }
      });
    });
  }
}
